import React, { FunctionComponent } from "react"

import { createUseStyles } from 'react-jss'
import { useCollapse } from 'react-collapsed'
import { Paragraph } from '../types/paragraph'
import ParagraphBlock from "./paragraph"

const useStyles = createUseStyles({
    readMoreButton: {
        background: 'white',
        color: '#007BFF',
        border: '0',
        textDecoration: 'underline',
        padding: '0',
        display: 'block',
        marginTop: '0.5em'
    },
    paragraph: {
        display: 'inline-block',
        margin: '0.5em 0'
    }
})

export interface ExpandableTextSectionProps {
    content: Array<Paragraph>
    maxInitialCharacterCount: number
}

const ExpandableTextSection: FunctionComponent<ExpandableTextSectionProps> = (props) => {
    const styles = useStyles()
    const { getToggleProps, getCollapseProps, isExpanded } = useCollapse()

    let nonCollapsedParagraphs = new Array<Paragraph>();
    let collapsedParagraphs = new Array<Paragraph>();
    let currentCharacterCount = 0;

    props.content.forEach(paragraph => {
        let length = paragraph.parts.map(part => part.content).join("").length;
        if (currentCharacterCount + length <= props.maxInitialCharacterCount) {
            nonCollapsedParagraphs.push(paragraph);
        } else {
            collapsedParagraphs.push(paragraph);
        }
        currentCharacterCount += length;
    });

    return (
        <>
            {nonCollapsedParagraphs.map((paragraph, index) => {
                return <ParagraphBlock {...paragraph} key={`paragraph-${index}`} className={styles.paragraph} textAlign='justify'/>
            })}
            {collapsedParagraphs.length > 0 && 
                <>
                    <section {...getCollapseProps()}>
                        {collapsedParagraphs.map((paragraph, index) => {
                            return <ParagraphBlock {...paragraph} key={`paragraph-${index}`} className={styles.paragraph} textAlign='justify'/>
                        })}
                    </section>
                    <button className={styles.readMoreButton} {...getToggleProps()}>
                        {isExpanded ? 'Read less...' : 'Read more...'}
                    </button>
                </>
            }
        </>
    )
}

export default ExpandableTextSection