import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentArea from "../components/contentArea"
import PersonCard from "../components/personCard"
import Heading from "../components/heading"
import content from "../../content/associates.json"
import { Paragraph } from "../types/paragraph"
import { getImage } from "gatsby-plugin-image"

const AssociatesPage = ({ data }) => (
    <Layout pageName="associates">
        <ContentArea>
            <Heading {...{ level: 1, content: "Associates" }} />
            { content.profiles.map((profile) => {
                var imageNode = data.allFile.nodes.find((node) => node.relativePath == profile.image) 
                    || data.allFile.nodes.find((node) => node.relativePath == "associates/default.png");
                var image = getImage(imageNode);
                return <PersonCard
                    image={image}
                    name={profile.name}
                    titles={profile.titles}
                    about={profile.about as Array<Paragraph>}
                    key={profile.name} />
            })}
        </ContentArea>
    </Layout>
)

export const Head = () => <Seo title="Associates" />

export const query = graphql`
  query {
    allFile(filter: {sourceInstanceName: {eq: "images"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
        relativePath
      }
    }
  }
`

export default AssociatesPage
