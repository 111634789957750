import React, { FunctionComponent, useState } from "react"

import { createUseStyles } from 'react-jss'
import { Paragraph } from '../types/paragraph'

const useStyles = createUseStyles({
    leftAlign: {
        textAlign: 'left'
    },
    centerAlign: {
        textAlign: 'center'
    },
    rightAlign: {
        textAlign: 'right'
    },
    justify: {
        textAlign: 'justify'
    }
})

export interface ParagraphProps extends Paragraph {
    className?: string,
    textAlign?: 'left' | 'center' | 'right' | 'justify'
}

const ParagraphBlock: FunctionComponent<ParagraphProps> = (props) => {
    const styles = useStyles()
    const renderHTML = (type: string, rawHTML: string, index: number) => React.createElement(type, { 
        dangerouslySetInnerHTML: { __html: rawHTML, },
        key: `part-${index}`
    });

    let textAlign = {};
    switch(props.textAlign) {
        case 'left': textAlign = styles.leftAlign; break;
        case 'center': textAlign = styles.centerAlign; break;
        case 'right': textAlign = styles.rightAlign; break;
        case 'justify': textAlign = styles.justify; break;
        default: textAlign = styles.leftAlign;
    }

    return (
        <p className={`${props.className} ${textAlign}`}>
            {props.parts.map((part, index) => {
                if (part.type == "text") {
                    return renderHTML("span", part.content, index);
                } else if (part.type == "italic") {
                    return renderHTML("i", part.content, index);
                } else if (part.type == "bold") {
                    return renderHTML("b", part.content, index);
                } else if (part.type == "underline") {
                    return renderHTML("u", part.content, index);
                } else if (part.type == "link") {
                    return <a key={`part-${index}`} href={part.href}>{part.content}</a>
                }
            })}
        </p>
    )
}

ParagraphBlock.defaultProps = {
    className: '',
    textAlign: 'left'
}

export default ParagraphBlock