import React, { FunctionComponent } from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import { createUseStyles } from 'react-jss'
import { Paragraph } from '../types/paragraph'
import ExpandableTextSection from './expandableTextSection'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 40
    },
    personCard: {
        flex: '0 0 100%',
        maxWidth: '100%',
        '@media(min-width: 768px)': {
            flex: '0 0 25%',
            maxWidth: '25%'
        },
        '@media(max-width: 991px)': {
            marginBottom: 30
        }
    },
    cardImage: {
        width: '100%',
        maxWidth: '100%'
    },
    cardTitles: {
        border: '1px solid #e8edf1',
        borderTop: 0,
        padding: '28px 30px 6px',
        textAlign: 'center',
        '@media(max-width: 1199px)': {
            padding: '28px 20px 6px'
        }
    },
    cardTitleName: {
        fontSize: '20px',
        marginBottom: '3px',
        marginTop: 0,
        fontFamily: "Playfair Display, serif",
        fontWeight: 'bold'
    },
    cardTitleDescription: {
        marginBottom: '26px'
    },
    aboutContainer: {
        padding: '2% 5%',
        flex: '0 0 90%',
        maxWidth: '90%',
        '@media(min-width: 768px)': {
            flex: '0 0 65%',
            maxWidth: '65%'
        }
    },
    cardLink: {
        float: 'right',
        marginTop: '3em'
    }
})

export interface PersonCardProps {
    image: IGatsbyImageData,
    name: string,
    titles: Array<string>,
    about: Array<Paragraph>
}

const PersonCard: FunctionComponent<PersonCardProps> = (props) => {
    const styles = useStyles()
    const nonCharacterRegex = /[^a-zA-Z]+/g;
    const id = props.name.replace(nonCharacterRegex, '-').toLowerCase();

    return (
        <>
            <div className={styles.container} id={id}>
                <div className={styles.personCard}>
                    <GatsbyImage
                        image={props.image}
                        alt={props.name}
                        loading="lazy"
                        className={styles.cardImage} />
                    <div className={styles.cardTitles}>
                        <div className={styles.cardTitleName}>{props.name}</div>
                        <div className={styles.cardTitleDescription}>{props.titles.join(", ")}</div>
                    </div>
                </div>
                <div className={styles.aboutContainer}>
                    <ExpandableTextSection content={props.about} maxInitialCharacterCount={1200} />
                    <a href={`referrals#${id}`} className={styles.cardLink}>Book an appointment >></a>
                </div>
            </div>
        </>
    )
}

export default PersonCard